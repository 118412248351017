import { defineComponent, reactive, toRefs } from "vue";
import { getByUrl } from "@/utils/http";
import { useRoute } from "vue-router";

interface Data {
  productDetails: {
    applicationScenarios: string;
    caseDetailsId: null;
    fieldParameter: string;
    firstTypeId: number;
    id: number;
    imgDetails: null;
    imgIntroduce: string;
    imgName: null;
    imgPath: string;
    imgTitle: string;
    secondTypeId: number;
    succId: null;
  } | null;
  productParameters: {
    [propName: string]: string;
  } | null;
}

export default defineComponent({
  name: "ProductDetails",
  setup() {
    const route = useRoute();
    const { productsId } = route.params;
    const data: Data = reactive({ productDetails: null, productParameters: null });
    const queryGoodsDetails = (productsId: string | string[]) => {
      getByUrl("/relatedProducts/getGoodsDetails", { productsId }).then(res => {
        const { productsList, jsonObject } = res;
        data.productDetails = productsList[0];
        data.productParameters = jsonObject;
      });
    };
    queryGoodsDetails(productsId);
    return {
      ...toRefs(data)
    };
  }
});
