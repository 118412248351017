<template>
  <div class="product-details">
    <div class="product" v-if="productDetails">
      <div><img :src="productDetails.imgPath" :alt="productDetails.imgTitle" /></div>
      <div>
        <h3>{{ productDetails.imgTitle }}</h3>
        <div>
          <p>{{ productDetails.imgIntroduce }}</p>
        </div>
        <div class="application">
          <h3>应用场景</h3>
          <ul>
            <li v-for="(item, index) in productDetails.applicationScenarios.split(',')" :key="`applicationScenarios-${index}`">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="table-box" v-if="productParameters">
      <table class="table" width="100%">
        <thead>
          <tr>
            <th>产品规格</th>
            <th>产品参数</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in Object.keys(productParameters)" :key="`productParameters-${index}`">
            <td>{{ item }}</td>
            <td>{{ productParameters[item] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.product-details {
  width: $effectiveArea;
  margin: 0 auto;
  .product {
    display: flex;
    margin-top: 22px;
    > div {
      &:first-child {
        position: relative;
        margin-right: 66px;
        min-width: 400px;
        height: 270px;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    ul {
      padding-left: 20px;
      li {
        margin-bottom: 5px;
      }
    }
  }
  .table-box {
    margin: 8px 0;
    .table {
      border: 1px solid #e5e5e5;
      border-collapse: collapse;
      width: 100%;
      height: 100%;
      th,
      td {
        height: 44px;
        border: 1px solid #e5e5e5;
        padding-left: 20px;
      }
      th {
        text-align: left;
        background-color: #4f4f4f;
        color: white;
      }
      td {
        color: #333333;
      }
    }
  }
}
</style>
